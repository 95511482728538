@tailwind base;
@tailwind components;
@tailwind utilities;

.home_page {
  background: url("./assets/image/home.jpeg");
}

@font-face {
  font-family: 'Neuropol';
  font-style: normal;
  font-weight: normal;
  src: local('Neuropol'), url('./fonts/neuropol.woff') format('woff');
  }